<template>
  <footer class="footer">
    <div class="container">
      <div class="footer__flex">
        <div class="footer__col">
          <img
            class="footer__brand"
            src="/img/logo-white.png"
            alt="Recicla logo"
          />
          <span class="footer__copyright">
            &copy; {{ year }} Recicla - todos os direitos reservados.
          </span>
        </div>

        <div class="footer__col">
          <a class="footer__anchor" title="Privacidade">
            Privacidade
          </a>
          <a class="footer__anchor" title="Termos">
            Termos
          </a>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  computed: {
    year () {
      const str = ''
      const strYear = 2019
      const year = new Date().getFullYear()
      return `${str} ${strYear}-${year} `
    }
  }
}
</script>

<style></style>
